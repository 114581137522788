import React from "react";
import { Result, Button } from "antd";
// import { isMobile } from 'react-device-detect';

const MobileIncompatibleMessage = () => {
  return (
    <div
      style={{
        display: "flex",
        backgroundColor:'white',
        justifyContent: "center",
        alignItems: "center",
        height: "98vh",
      }}
    >
      <Result
        status="warning"
        style={{ color: "red" }}
        title="This website is not accessible for mobile devices."
        subTitle="Please visit this website from a desktop."
        extra={[
          <Button
            style={{ color: "blue", marginTop: "5%" }}
            key="back"
            onClick={() => window.history.back()}
          >
            Go Back
          </Button>,
        ]}
      />
    </div>
  );
};

export default MobileIncompatibleMessage;
