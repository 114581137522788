import React, { useState } from "react";
import {
  Menu,
  Dropdown,
  Avatar,
  Form,
  Input,
  Modal,
  Button,
  message,
} from "antd";
import { connect } from "react-redux";
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/actions/Auth";
import { useForm } from "antd/lib/form/Form";
import { post } from "configs/client";
import Loader from "components/common/loader";

const menuItem = [
  {
    title: "Change Password",
    icon: EditOutlined,
    path: "/",
    key: "change-password",
  },
];
export const changePasswordApi = (body) => {
  return post("dash/change-password/", body);
};
export const NavProfile = ({ signOut }) => {
  const [form] = useForm();
  const [state, setState] = useState({
    isVisible: false,
    isLoading: false,
  });
  const { isVisible, isLoading } = state;
  const onChangePasswordClick = (key) => {
    setState((state) => ({
      ...state,
      isVisible: !isVisible,
    }));
  };

  const handleChangePasswordSubmit = () => {
    setState((state) => ({
      ...state,
      isLoading: true,
    }));
    const body = form.getFieldsValue();
    changePasswordApi(body)
      .then((res) => {
        setState((state) => ({
          ...state,
          isLoading: false,
        }));
        onChangePasswordClick();
      })
      .catch((e) => {
        const errorFields = {
          old_password: e?.response?.data?.old_password?.[0],
          new_password: e?.response?.data?.new_password?.[0],
        };
        const errorFieldsKeys = Object.keys(errorFields);
        errorFieldsKeys.forEach((key) => {
          form?.setFields([
            {
              name: key,
              errors:
                errorFields?.[key]?.length > 0 ? [errorFields?.[key]] : "",
            },
          ]);
        });
        message.error(
          e?.response?.data?.non_fields_error?.[0] ||e?.response?.data?.detail|| "Something wents wrong."
        );
        setState((state) => ({
          ...state,
          isLoading: false,
        }));
      });
  };

  const validatePassword = (_, value) => {
    const regex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!value || regex.test(value)) {
      return Promise.resolve();
    }
    return Promise.reject(
      new Error(
        "Password must be at least 8 characters long and contain at least one special character, one uppercase letter, and one digit."
      )
    );
  };

  // Form layout
  const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  const profileImg =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQY0yYjpyWPNv45H5uhu3ZuRlDji9iHy5IlhDnop-LXzi2Zk1-RLoBtnZaVOnhY3aQHhwH9mUYeZX0&usqp=CAU&ec=48665701";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar size={48} src={profileImg} />
          <div className="pl-3">
            <h4 className="mb-0">Amkette Employee</h4>
            <span className="text-muted">Admin</span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item
                key={i}
                onClick={() => {
                  onChangePasswordClick(el?.key);
                }}
              >
                <a>
                  <Icon type={el?.icon} />
                  <span className="font-weight-normal">{el?.title}</span>
                </a>
              </Menu.Item>
            );
          })}
          <Menu.Item key={menuItem?.length + 1} onClick={(e) => signOut()}>
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );

  return (
    <div>
      <Loader isLoading={isLoading} />
      <Dropdown
        placement="bottomRight"
        overlay={profileMenu}
        trigger={["click"]}
      >
        <Menu className="d-flex align-item-center" mode="horizontal">
          <Menu.Item key="profile">
            <Avatar src={profileImg} />
          </Menu.Item>
        </Menu>
      </Dropdown>
      <Modal
        title="Change Password"
        visible={isVisible}
        onOk={() => form.submit()}
        onCancel={() => onChangePasswordClick("change-password")}
      >
        <Form
          {...layout}
          form={form}
          name="passwordForm"
          onFinish={handleChangePasswordSubmit}
          initialValues={{ remember: true }}
        >
          {/* Old password field */}
          <Form.Item
            name="old_password"
            label="Old Password"
            rules={[
              { required: true, message: "Please input your old password!" },
            ]}
          >
            <Input.Password disabled={isLoading} />
          </Form.Item>

          {/* New password field */}
          <Form.Item
            name="new_password"
            label="New Password"
            rules={[
              { required: true, message: "Please input your new password!" },
              { validator: validatePassword },
            ]}
          >
            <Input.Password disabled={isLoading} />
          </Form.Item>

          {/* Submit button */}
        </Form>{" "}
      </Modal>
    </div>
  );
};

export default connect(null, { signOut })(NavProfile);
