import { message } from "antd";
import { post } from "configs/client";
import { AUTH_TOKEN, REFRESH_TOKEN } from "redux/constants/Auth";
const getRefreshTokenApi = (body) => {
  return post("dash/login/refresh/", body);
};
const signInWithRefresh = () => {
  const token = localStorage.getItem("refresh_token");

  getRefreshTokenApi({ refresh: ` ${token}` })
    .then((res) => {
      if (res) {
        localStorage.removeItem("auth_token");
        localStorage.setItem(AUTH_TOKEN, res?.data?.access);
        // localStorage.setItem(REFRESH_TOKEN, res?.refresh);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
      // message.error(
      //   "Please refresh the page to get data as your token is expired."
      // );
      window.location.reload();
    })
    .catch((error) => {
      if (
        error &&
        error.response &&
        error.response.status === 401
        // error?.response?.data?.code === "refresh_token_not_valid"
      ) {
        localStorage.removeItem("auth_token");
        window.location.reload();

        // localStorage.removeItem("refresh_token");
      }
      console.log({ error });
    });
};
export { getRefreshTokenApi, signInWithRefresh };
