import { Alert, Space, Spin } from "antd";
const Loader = ({ isLoading }) => {
  return (
    <>
      {isLoading ? (
        <div className="fullscreen-loader">
          <Spin tip="Loading...">
            <div className="content" />
          </Spin>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default Loader;
