import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from "configs/AppConfig";
import { useSelector } from "react-redux";
import ErrorOne from "views/auth-views/errors/error-page-1";

export const AppViews = () => {
  const { permissions } = useSelector((state) => ({
    permissions: state?.auth?.permissions,
  }));
  const canViewList = permissions?.includes("view_dashboard");

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${APP_PREFIX_PATH}/home`}
          component={
            canViewList
              ? lazy(() => import(`./home`))
              : lazy(() => import(`./home`))
          }
        />
        <Route
          path={`${APP_PREFIX_PATH}/auth/editorPage`}
          component={lazy(() =>
            import(`../auth-views/authentication/editorPage`)
          )}
        />
        <Route
          path={`${APP_PREFIX_PATH}/view1`}
          component={lazy(() => import(`./view1`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/apps`}
          component={lazy(() => import(`./apps`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/charts`}
          component={lazy(() => import(`./charts`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/Products`}
          component={lazy(() => import(`./Products`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/accessories`}
          component={lazy(() => import(`./Products/accessories`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/consumables`}
          component={lazy(() => import(`./Products/consumables`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/instruments`}
          component={lazy(() => import(`./Products/instruments`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/spares`}
          component={lazy(() => import(`./Products/spares`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/document`}
          component={lazy(() => import(`./docLibrary`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/media`}
          component={lazy(() => import("./mediaLibrary"))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/category`}
          component={lazy(() => import(`./category`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/brands`}
          component={lazy(() => import(`./brands`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/testimonials`}
          component={lazy(() => import(`./testimonials`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/product_line`}
          component={lazy(() => import(`./productLine`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/promo_code`}
          component={lazy(() => import(`./promoCode`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/sections`}
          component={lazy(() => import(`./section`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/banners`}
          component={lazy(() => import(`./banner`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/quotes`}
          component={lazy(() => import(`./quotes`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/quote/:id/revisions`}
          component={lazy(() => import(`./quotes/revision/revisionList`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/quote/:id/revision/edit/:id`}
          component={lazy(() => import(`./quotes/revision/revisionForm/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/quote/:id/revision/add`}
          component={lazy(() => import(`./quotes/revision/revisionForm/index`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/siteInfo`}
          component={lazy(() => import(`./siteInfo`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/menu`}
          component={lazy(() => import(`./menus`))}
        />

        <Route
          path={`${APP_PREFIX_PATH}/customPages`}
          component={lazy(() => import(`./customPages`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/userList`}
          component={lazy(() => import(`./users`))}
        />
        <Route
          path={`${APP_PREFIX_PATH}/product-upload`}
          component={lazy(() => import(`./bulk-upload`))}
        />

        {/* <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} /> */}
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
