// import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
// import {
// 	AUTH_TOKEN,
// 	SIGNIN,
// 	SIGNOUT,
// 	SIGNUP,
// 	SIGNIN_WITH_GOOGLE,
// 	SIGNIN_WITH_FACEBOOK
// } from '../constants/Auth';
// import {
// 	showAuthMessage,
// 	authenticated,
// 	signOutSuccess,
// 	signUpSuccess,
// 	signInWithGoogleAuthenticated,
// 	signInWithFacebookAuthenticated
// } from "../actions/Auth";

// import FirebaseService from 'services/FirebaseService'

// export function* signInWithFBEmail() {
//   yield takeEvery(SIGNIN, function* ({payload}) {
// 		const {email, password} = payload;
// 		try {
// 			const user = yield call(FirebaseService.signInEmailRequest, email, password);
// 			if (user.message) {
// 				yield put(showAuthMessage(user.message));
// 			} else {
// 				localStorage.setItem(AUTH_TOKEN, user.user.uid);
// 				yield put(authenticated(user.user.uid));
// 			}
// 		} catch (err) {
// 			yield put(showAuthMessage(err));
// 		}
// 	});
// }

// export function* signOut() {
//   yield takeEvery(SIGNOUT, function* () {
// 		try {
// 			const signOutUser = yield call(FirebaseService.signOutRequest);
// 			if (signOutUser === undefined) {
// 				localStorage.removeItem(AUTH_TOKEN);
// 				yield put(signOutSuccess(signOutUser));
// 			} else {
// 				yield put(showAuthMessage(signOutUser.message));
// 			}
// 		} catch (err) {
// 			yield put(showAuthMessage(err));
// 		}
// 	});
// }

// export function* signUpWithFBEmail() {
//   yield takeEvery(SIGNUP, function* ({payload}) {
// 		const {email, password} = payload;
// 		try {
// 			const user = yield call(FirebaseService.signUpEmailRequest, email, password);
// 			if (user.message) {
// 				yield put(showAuthMessage(user.message));
// 			} else {
// 				localStorage.setItem(AUTH_TOKEN, user.user.uid);
// 				yield put(signUpSuccess(user.user.uid));
// 			}
// 		} catch (error) {
// 			yield put(showAuthMessage(error));
// 		}
// 	}
// 	);
// }

// export function* signInWithFBGoogle() {
//   yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
// 		try {
// 			const user = yield call(FirebaseService.signInGoogleRequest);
// 			if (user.message) {
// 				yield put(showAuthMessage(user.message));
// 			} else {
// 				localStorage.setItem(AUTH_TOKEN, user.user.uid);
// 				yield put(signInWithGoogleAuthenticated(user.user.uid));
// 			}
// 		} catch (error) {
// 			yield put(showAuthMessage(error));
// 		}
// 	});
// }

// export function* signInWithFacebook() {
//   yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
// 		try {
// 			const user = yield call(FirebaseService.signInFacebookRequest);
// 			if (user.message) {
// 				yield put(showAuthMessage(user.message));
// 			} else {
// 				localStorage.setItem(AUTH_TOKEN, user.user.uid);
// 				yield put(signInWithFacebookAuthenticated(user.user.uid));
// 			}
// 		} catch (error) {
// 			yield put(showAuthMessage(error));
// 		}
// 	});
// }

// export default function* rootSaga() {
//   yield all([
// 		fork(signInWithFBEmail),
// 		fork(signOut),
// 		fork(signUpWithFBEmail),
// 		fork(signInWithFBGoogle),
// 		fork(signInWithFacebook)
//   ]);
// }

import { all, takeEvery, put, fork, call } from "redux-saga/effects";
import axios from "axios";
import {
  AUTH_TOKEN,
  SIGNIN,
  REFRESHSIGNIN,
  SIGNOUT,
  SIGNUP,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_FACEBOOK,
  REFRESH_TOKEN,
  PERMISSIONS,
  IS_SUPERUSER,
} from "../constants/Auth";
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
  signUpSuccess,
  signInWithGoogleAuthenticated,
  signInWithFacebookAuthenticated,
} from "../actions/Auth";
import { getBaseURL, getRefreshToken } from "components/common/others";
import { post } from "configs/client";
import { getRefreshTokenApi } from "services/login";
const BASE_URL = getBaseURL();

// Create an Axios instance with the base URL
const api = axios.create({
  baseURL: BASE_URL,
  headers: {
    //  "ngrok-skip-browser-warning": "true"
     },
});

// Helper function to handle API requests
function* requestApi(method, url, data) {
  try {
    const response = yield api[method](url, data);
    return response.data;
  } catch (error) {
    throw error.response.data.error;
  }
}

export function* signInWithEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const user = yield call(requestApi, "post", "/dash/login/", {
        email,
        password,
      });
      if (user?.access) {
        localStorage.setItem(AUTH_TOKEN, user?.access);
        localStorage.setItem(REFRESH_TOKEN, user?.refresh);
        localStorage.setItem(PERMISSIONS, user?.permissions);
        localStorage.setItem(IS_SUPERUSER, user?.is_superuser);
        yield put(authenticated(user?.access));
      } else {
        yield put(showAuthMessage(user?.message));
      }
    } catch (err) {
      yield put(showAuthMessage("You are using a wrong credentials"));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      localStorage.removeItem(AUTH_TOKEN);
      localStorage.removeItem(REFRESH_TOKEN);
      localStorage.removeItem(PERMISSIONS);

      yield put(signOutSuccess());
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signUpWithEmail() {
  yield takeEvery(SIGNUP, function* ({ payload }) {
    const { email, password } = payload;
    try {
      const user = yield call(requestApi, "post", "/signup", {
        email,
        password,
      });
      if (user.token) {
        localStorage.setItem(AUTH_TOKEN, user.token);
        yield put(signUpSuccess(user.token));
      } else {
        yield put(showAuthMessage(user.message));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* signInWithGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
    try {
      const user = yield call(requestApi, "post", "/signin/google");
      if (user.token) {
        localStorage.setItem(AUTH_TOKEN, user.token);
        yield put(signInWithGoogleAuthenticated(user.token));
      } else {
        yield put(showAuthMessage(user.message));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
    try {
      const user = yield call(requestApi, "post", "/signin/facebook");
      if (user.token) {
        localStorage.setItem(AUTH_TOKEN, user.token);
        yield put(signInWithFacebookAuthenticated(user.token));
      } else {
        yield put(showAuthMessage(user.message));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(signInWithEmail),
    // fork(signInWithRefresh),
    fork(signOut),
    fork(signUpWithEmail),
    fork(signInWithGoogle),
    fork(signInWithFacebook),
  ]);
}
