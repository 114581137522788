import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Menu, Grid, Image } from "antd";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import navigationConfig from "configs/NavigationConfig";
import { connect } from "react-redux";
import { SIDE_NAV_LIGHT, NAV_TYPE_SIDE } from "constants/ThemeConstant";
import utils from "utils";
import { onMobileNavToggle } from "redux/actions/Theme";
import { IS_SUPERUSER, PERMISSIONS } from "redux/constants/Auth";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = ["AMMAKETE"];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const SideNavContent = (props) => {
  const [activeKeys, setActiveKeys] = useState([]);
  const [permissions, setPermissions] = useState(
    localStorage.getItem(PERMISSIONS)
  );

  const [superUser, setSuperUser] = useState("");

  const [canManageHeader, setCanManageHeader] = useState("");
  const [canManageFooter, setCanManageFooter] = useState("");
  const [canViewSiteInfo, setCanViewSiteInfo] = useState("");
  const [canChangeSiteInfo, setCanChangeSiteInfo] = useState("");

  const [canManageBulkUpload, setCanManageBulkUpload] = useState("");
  const [canAddMedia, setCanAddMedia] = useState("");
  const [canViewMedia, setCanViewMedia] = useState("");
  const [canAddPage, setCanAddPage] = useState("");
  const [canViewPage, setCanViewPage] = useState("");
  const [canAddCategory, setCanAddCategory] = useState("");
  const [canViewCategory, setCanViewCategory] = useState("");
  const [canAddBrands, setCanAddBrands] = useState("");
  const [canViewBrands, setCanViewBrands] = useState("");
  const [canAddTestimonials, setCanAddTestimonials] = useState("");
  const [canViewTestimonials, setCanViewTestimonials] = useState("");
  const [canAddProductLine, setCanAddProductLine] = useState("");
  const [canViewProductLine, setCanViewProductLine] = useState("");
  const [canAddSection, setCanAddSection] = useState("");
  const [canViewSection, setCanViewSection] = useState("");
  const [canAddHomeBanner, setCanAddHomeBanner] = useState("");
  const [canViewHomeBanner, setCanViewHomeBanner] = useState("");
  const [canAddAdBanner, setCanAddAdBanner] = useState("");
  const [canViewAdBanner, setCanViewAdBanner] = useState("");
  const [canAddProduct, setCanAddProduct] = useState("");
  const [canViewProduct, setCanViewProduct] = useState("");
  const [canViewQuote, setCanViewQuote] = useState("");
  const [canViewPromo, setCanViewPromo] = useState("");
  const [canAddPromo, setCanAddPromo] = useState("");

  useEffect(() => {
    const defaultSelectedKeys = [props?.routeInfo?.key];
    const defaultOpenKeys = setDefaultOpen(props?.routeInfo?.key);

    setActiveKeys([...defaultSelectedKeys, ...defaultOpenKeys]);
  }, [props?.routeInfo?.key]);

  useEffect(() => {
    setCanManageHeader(permissions?.includes("manage_header"));
    setCanManageFooter(permissions?.includes("manage_footer"));
    setCanViewSiteInfo(permissions?.includes("view_sitesetting"));
    setCanChangeSiteInfo(permissions?.includes("change_sitesetting"));

    setCanManageBulkUpload(
      permissions?.includes("manage_productbulkuploadtask")
    );
    setCanAddMedia(permissions?.includes("add_media"));
    setCanViewMedia(permissions?.includes("view_media"));
    setCanAddPage(permissions?.includes("add_page"));
    setCanViewPage(permissions?.includes("view_page"));
    setCanAddCategory(permissions?.includes("add_category"));
    setCanViewCategory(permissions?.includes("view_category"));
    setCanAddBrands(permissions?.includes("add_brands"));
    setCanViewBrands(permissions?.includes("view_brands"));
    setCanAddTestimonials(permissions?.includes("add_testimonial"));
    setCanViewTestimonials(permissions?.includes("view_testimonial"));
    setCanAddProductLine(permissions?.includes("add_productline"));
    setCanViewProductLine(permissions?.includes("view_productline"));
    setCanAddSection(permissions?.includes("add_section"));
    setCanViewSection(permissions?.includes("view_section"));
    setCanAddHomeBanner(permissions?.includes("add_banner"));
    setCanViewHomeBanner(permissions?.includes("view_banner"));
    setCanAddAdBanner(permissions?.includes("add_adbanner"));
    setCanViewAdBanner(permissions?.includes("view_adbanner"));
    setCanAddProduct(permissions?.includes("add_product"));
    setCanViewProduct(permissions?.includes("view_product"));
    setCanViewQuote(permissions?.includes("view_quote"));
    setSuperUser(permissions?.includes("superuser"));
    setCanViewPromo(permissions?.includes("view_promocode"));
    setCanAddPromo(permissions?.includes("add_promocode"));
  }, []);

  const {
    sideNavTheme,
    routeInfo,
    hideGroupTitle,
    localization,
    onMobileNavToggle,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes("lg");
  const closeMobileNav = () => {
    if (isMobile) {
      onMobileNavToggle(false);
    }
  };
  const checkMenuCondition = (submenu) => {
    if (submenu?.key === "Menu") {
      if (canManageFooter || canManageHeader) {
        return true;
      } else {
        return false;
      }
    } else if (submenu?.key === "Apps-Master") {
      if (
        canAddProduct ||
        canViewProduct ||
        canManageBulkUpload ||
        canAddBrands ||
        canViewBrands ||
        canAddCategory ||
        canViewCategory ||
        canAddProductLine ||
        canViewProductLine ||
        superUser
      ) {
        return true;
      } else {
        return false;
      }
    } else if (submenu?.key === "Apps-Web") {
      if (
        canAddSection ||
        canAddAdBanner ||
        canAddHomeBanner ||
        canAddPage ||
        canAddTestimonials ||
        canViewSection ||
        canViewAdBanner ||
        canViewHomeBanner ||
        canViewSiteInfo ||
        canViewTestimonials
      ) {
        return true;
      } else {
        return false;
      }
    } else if (submenu?.key === "Apps-Project") {
      if (canAddProduct || canViewProduct) {
        return true;
      } else {
        return false;
      }
    } else if (submenu?.key === "Banner") {
      if (
        canViewAdBanner ||
        canViewHomeBanner ||
        canAddAdBanner ||
        canAddHomeBanner
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  const checkMenuConditionChildless = (submenu) => {
    if (submenu?.key === "ProductUpload" && !canManageBulkUpload) {
      return false;
    } else if (submenu?.key === "SiteInfo" && !canViewSiteInfo) {
      return false;
    } else if (submenu?.key === "Media" && !canViewMedia && !canAddMedia) {
      return false;
    } else if (submenu?.key === "Pages" && !canViewPage && !canAddPage) {
      return false;
    } else if (submenu?.key === "Users" && !superUser) {
      return false;
    } else if (
      submenu?.key === "Category" &&
      !canViewCategory &&
      !canAddCategory
    ) {
      return false;
    } else if (submenu?.key === "Brands" && !canViewBrands && !canAddBrands) {
      return false;
    } else if (
      submenu?.key === "Testimonials" &&
      !canViewTestimonials &&
      !canAddTestimonials
    ) {
      return false;
    } else if (
      submenu?.key === "ProductLine" &&
      canViewProductLine &&
      canAddProductLine
    ) {
      return false;
    } else if (submenu?.key === "PromoCode" && !canViewPromo && !canAddPromo) {
      return false;
    } else if (
      submenu?.key === "Sections" &&
      !canViewSection &&
      !canAddSection
    ) {
      return false;
    } else if (submenu?.key === "Quotes" && !canViewQuote) {
      return false;
    } else {
      return true;
    }
  };
  const renderSubMenu = (submenu) => {
    if (!checkMenuCondition(submenu)) {
      return;
    } else {
      return (
        <SubMenu
          style={{ paddingLeft: "4px" }}
          key={submenu.key}
          title={
            <span style={{ paddingLeft: "4px" }}>
              {setLocale(localization, submenu?.title)}
            </span>
          }
          icon={
            submenu.icon ? (
              <Icon type={submenu.icon} />
            ) : (
              <Image
                src={submenu?.image}
                height={18}
                width={18}
                style={{ padding: "28px", marginRight: "2px" }}
              />
            )
          }
        >
          {submenu.submenu.map((subMenu) =>
            subMenu.submenu?.length > 0
              ? renderSubMenu(subMenu) // Recursive call to handle nested submenus
              : checkCondition(subMenu) && (
                  <Menu.Item key={subMenu.key}>
                    {subMenu.icon ? <Icon type={subMenu?.icon} /> : null}
                    <span>{setLocale(localization, subMenu?.title)}</span>
                    <Link onClick={() => closeMobileNav()} to={subMenu?.path} />
                  </Menu.Item>
                )
          )}
        </SubMenu>
      );
    }
  };

  const checkCondition = (subMenu) => {
    if (subMenu.key === "Header-Menu" && !canManageHeader) {
      return false;
    } else if (subMenu.key === "Footer-Menu" && !canManageFooter) {
      return false;
    } else if (
      subMenu?.key === "Testimonials" &&
      !canAddTestimonials &&
      !canViewTestimonials
    ) {
      return false;
    } else if (
      subMenu?.key === "Home-Banners" &&
      !canAddHomeBanner &&
      !canViewHomeBanner
    ) {
      return false;
    } else if (
      subMenu?.key === "Ad-Banner" &&
      !canAddAdBanner &&
      !canViewAdBanner
    ) {
      return false;
    } else if (
      subMenu?.key === "SiteInfo" &&
      !canChangeSiteInfo &&
      !canViewAdBanner
    ) {
      return false;
    } else if (
      subMenu?.key === "ProductLine" &&
      !canViewProductLine &&
      !canAddProductLine
    ) {
      return false;
    } else if (subMenu?.key === "PromoCode" && !canViewPromo && !canAddPromo) {
      return false;
    } else if (
      subMenu?.key === "Sections" &&
      !canViewSection &&
      !canAddSection
    ) {
      return false;
    } else if (subMenu?.key === "ProductUpload" && !canManageBulkUpload) {
      return false;
    } else if (subMenu?.key === "SiteInfo" && !canViewSiteInfo) {
      return false;
    } else if (subMenu?.key === "Media" && !canViewMedia && !canAddMedia) {
      return false;
    } else if (subMenu?.key === "Pages" && !canViewPage && !canAddPage) {
      return false;
    } else if (subMenu?.key === "Users" && !superUser) {
      return false;
    } else if (
      subMenu?.key === "Category" &&
      !canViewCategory &&
      !canAddCategory
    ) {
      return false;
    } else if (subMenu?.key === "Brands" && !canViewBrands && !canAddBrands) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <Menu
      theme={sideNavTheme === SIDE_NAV_LIGHT ? "light" : "dark"}
      mode="inline"
      style={{ height: "100%", borderRight: 0 , marginTop:'10px'}}
      selectedKeys={activeKeys}
      defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
      className={hideGroupTitle ? "hide-group-title" : ""}
    >
      {navigationConfig.map((menu) =>
        menu.submenu?.length > 0
          ? renderSubMenu(menu) // Call the renderSubMenu function for top-level submenus
          : checkMenuConditionChildless(menu) && (
              <Menu.Item key={menu.key}>
                {menu.icon ? (
                  <Icon type={menu?.icon} />
                ) : (
                  <Image
                    src={menu?.image}
                    height={18}
                    width={18}
                    style={{ padding: "1px", marginTop: "2px" }}
                  />
                )}
                <span> {setLocale(localization, menu?.title)}</span>
                {menu.path ? (
                  <Link onClick={() => closeMobileNav()} to={menu?.path} />
                ) : null}
              </Menu.Item>
            )
      )}
    </Menu>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu?.key}
            popupClassName="top-nav-menu"
            title={
              <span className="d-flex align-items-center">
                {menu.icon ? (
                  <Icon type={menu?.icon} />
                ) : (
                  <Image
                    src={menu?.image}
                    height={18}
                    width={18}
                    style={{ padding: "1px", marginTop: "2px" }}
                  />
                )}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  popupClassName="top-nav-menu"
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : (
                      <Image
                        src={subMenuFirst?.image}
                        height={18}
                        width={18}
                        style={{ padding: "1px", marginTop: "2px" }}
                      />
                    )
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : (
                    <Image
                      src={subMenuFirst?.image}
                      height={18}
                      width={18}
                      style={{ padding: "1px", marginTop: "2px" }}
                    />
                  )}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props?.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
