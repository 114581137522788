import { toast } from "react-hot-toast";
import YouTube from "react-youtube"; // Import the YouTube component
import { CloseOutlined } from "@ant-design/icons";

export const getBaseURL = () => {
  if (!process.env.REACT_APP_ENV) {
    //return "https://059d-2401-4900-1c97-dfa2-1deb-ac54-56ba-1f5f.ngrok-free.app/";
    return "https://api.anmalliance.com/";
  } else if (process.env.REACT_APP_ENV === "staging") {
    return process.env.REACT_APP_STAGING_URL;
  } else if (process.env.REACT_APP_ENV === "production") {
    return process.env.REACT_APP_PRODUCTION_URL;
  } else {
    return "";
  }
};
export const getToken = async () => {
  try {
    const token = await localStorage.getItem("auth_token");
    return `Bearer ${token}` || "";
  } catch (e) {
    return e;
  }
};
export const getRefreshToken = async () => {
  try {
    const token = await localStorage.getItem("refresh_token");
    if (token !== null) {
      return token;
    }
  } catch (e) {
    return e;
  }
};

export const handleValuesChange = (form) => {
  const { list_price, gst } = form.getFieldsValue();
  // Calculate MRP using List Price and GST
  if (list_price !== undefined) {
    const calculatedGstValue = gst || 18; // Use the GST value or default to 18
    const calculatedMrp = list_price + (list_price * calculatedGstValue) / 100;

    // Set the calculated MRP value in the form
    form.setFieldsValue({ mrp: calculatedMrp });
  }
};

export const showToast = (message) => {
  toast.remove();
  message &&
    toast(message, {
      style: {
        background: "#333",
        color: "#fff",
      },
    });
};
export const isYouTubeUrl = (url) => {
  return /^https?:\/\/(www\.)?youtube\.com/.test(url);
};

export const renderYouTubeVideo = (url, deleteVideo) => {
  const videoId = new URL(url).searchParams.get("v");
  const opts = {
    height: "140",
    width: "180",
  };

  return (
    <div className="image-wrapper">
      <div
        className="cancel-"
        style={{
          marginTop: "20px",
          marginLeft: "70%",
          color: "red",
          backgroundColor: "#FEF9E7",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          deleteVideo();
        }}
      >
        <CloseOutlined title="Delete Video" />
      </div>
      <YouTube videoId={videoId} opts={opts} width={130} height={130} />
    </div>
  );
};
export const renderNormalVideo = (url, deleteVideo) => {
  return (
    <div className="image-wrapper">
      <div
        className="cancel-"
        style={{
          marginTop: "20px",
          marginLeft: "70%",
          color: "red",
          backgroundColor: "#FEF9E7",
          borderRadius: "50%",
          width: "30px",
          height: "30px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          cursor: "pointer",
        }}
        onClick={() => {
          deleteVideo();
        }}
      >
        <CloseOutlined title="Delete Video" />
      </div>

      <video width={190} height={140} controls>
        <source src={url} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
    </div>
  );
};
export const formatDate = (timestamp) => {
  const date = new Date(timestamp);

  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");

  const formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
};

export const formatTime = (timeString) => {
  // Parse the input time string into a Date object
  // Parse the input time string into a Date object
  const time = new Date(timeString);

  if (isNaN(time.getTime())) {
    return null;
  }

  // Add 5 hours and 30 minutes to convert to IST
  time.setUTCHours(time.getUTCHours() + 5);
  time.setUTCMinutes(time.getUTCMinutes() + 30);

  // Handle overflow for hours and minutes
  const hours = time.getUTCHours() % 24;
  const minutes = time.getUTCMinutes() % 60;
  const seconds = time.getUTCSeconds();
  const milliseconds = time.getUTCMilliseconds();

  // Format time as 'hh:mm[:ss[.uuuuuu]]'
  let formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
  if (seconds || milliseconds) {
    formattedTime += `:${seconds.toString().padStart(2, "0")}`;
    if (milliseconds) {
      formattedTime += `.${milliseconds.toString().padStart(3, "0")}`;
    }
  }

  return formattedTime;
};

export const formatDateWithTimeZone = (timestamp) => {
  const date = new Date(timestamp);
  return date.toISOString();
};

export const isValidEmail = (email) => {
  const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailPattern.test(email);
};

export const RemoveQueryParameter = (history, location) => {
  const params = new URLSearchParams(location.search);
  params.delete("page");
  history.replace({ search: params.toString() });

  return null;
};
