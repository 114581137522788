import {
  ApiOutlined,
  AppstoreOutlined,
  AuditOutlined,
  BulbOutlined,
  CodeSandboxOutlined,
  DashboardOutlined,
  DeploymentUnitOutlined,
  FileImageOutlined,
  FilePptOutlined,
  UsergroupAddOutlined,
  InfoCircleOutlined,
  SelectOutlined,
  ShakeOutlined,
  ShopOutlined,
  SisternodeOutlined,
  SoundOutlined,
  FormOutlined,
  StarOutlined,
  ToTopOutlined,
  ToolOutlined,
  UploadOutlined,
  SolutionOutlined,
  MoneyCollectOutlined,
  ToolOutlined as InteractionOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "Home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "Dashboard",
    icon: ShopOutlined,
    // image: DashboardSvg ,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "Apps-Master",
    path: `${APP_PREFIX_PATH}/Products`,
    title: "Masters",
    icon: SolutionOutlined,

    // image:ProductSvg,
    breadcrumb: false,
    submenu: [
      {
        key: "Media",
        path: `${APP_PREFIX_PATH}/media/media-library`,
        title: "Media Upload",
        icon: FileImageOutlined,
        // image:MediaSvg,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "ProductUpload",
        path: `${APP_PREFIX_PATH}/product-upload/job-list`,
        title: "Bulk Upload",
        icon: UploadOutlined,
        // image:MediaSvg,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Users",
        path: `${APP_PREFIX_PATH}/userList/user-list`,
        title: "Users",
        icon: UsergroupAddOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: "Apps-Project",
        path: `${APP_PREFIX_PATH}/Products`,
        title: "Products",
        icon: CodeSandboxOutlined,
        // image:ProductSvg,
        breadcrumb: true,
        submenu: [
          {
            key: "Apps-Project-Instruments",
            path: `${APP_PREFIX_PATH}/instruments/instruments-list`,
            title: "Instruments",
            icon: DashboardOutlined,
            breadcrumb: true,
          },
          {
            key: "Spare",
            path: `${APP_PREFIX_PATH}/spares/spares-list`,
            title: "Spares",
            icon: ToolOutlined,
            breadcrumb: true,
          },
          {
            key: "Accessories",
            path: `${APP_PREFIX_PATH}/accessories/accessories-list`,
            title: "Accessories",
            icon: StarOutlined,
            breadcrumb: true,
          },
          {
            key: "Consumable",
            path: `${APP_PREFIX_PATH}/consumables/consumables-list`,
            title: "Consumable",
            icon: ApiOutlined,
            breadcrumb: true,
          },
        ],
      },
      {
        key: "Category",
        // path: `${APP_PREFIX_PATH}/apps`,
        path: `${APP_PREFIX_PATH}/category/category-list`,
        title: "Category",
        icon: DeploymentUnitOutlined,
        // image:CatImage,
        breadcrumb: false,
      },
      {
        key: "Brands",
        path: `${APP_PREFIX_PATH}/brands/brand-list`,
        title: "Brand",
        icon: SoundOutlined,
        breadcrumb: true,
      },
      {
        key: "ProductLine",
        path: `${APP_PREFIX_PATH}/product_line/product_line-list`,
        title: "Product Line",
        icon: SisternodeOutlined,
        breadcrumb: true,
      },
    ],
  },

  {
    key: "PromoCode",
    path: `${APP_PREFIX_PATH}/promo_code/promo_code-list`,
    title: "Promotional Code",
    icon: MoneyCollectOutlined,
    breadcrumb: true,
  },
  {
    key: "Quotes",
    path: `${APP_PREFIX_PATH}/quotes/quotes-list`,
    title: "Quotes",
    icon: AuditOutlined,
    breadcrumb: false,
  },
  {
    key: "Apps-Web",
    path: `${APP_PREFIX_PATH}/site-info`,
    title: "Website Setup",
    icon: InteractionOutlined,
    // image:ProductSvg,
    breadcrumb: true,
    submenu: [
      {
        key: "SiteInfo",
        path: `${APP_PREFIX_PATH}/siteInfo`,
        title: "Site Info",
        icon: InfoCircleOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: "Banner",
        path: `${APP_PREFIX_PATH}/banners`,
        title: "Banners",
        icon: ShakeOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "Home-Banners",
            path: `${APP_PREFIX_PATH}/banners/banner-list`,
            title: "Home Banners",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "Ad-Banner",
            path: `${APP_PREFIX_PATH}/banners/add-banner`,
            title: "Ad Banner",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "Pages",
        path: `${APP_PREFIX_PATH}/customPages/customPage-list`,
        title: "Pages",
        icon: FilePptOutlined,
        breadcrumb: true,
        submenu: [],
      },

      {
        key: "Testimonials",
        path: `${APP_PREFIX_PATH}/testimonials/testimonial-list`,
        title: "Testimonials",
        icon: FormOutlined,
        breadcrumb: false,
        submenu: [],
      },

      {
        key: "Sections",
        path: `${APP_PREFIX_PATH}/sections/section-list`,
        title: "Sections",
        icon: BulbOutlined,
        breadcrumb: true,
      },

      {
        key: "Menu",
        path: `${APP_PREFIX_PATH}/menu`,
        title: "Menu",
        icon: ApiOutlined,
        submenu: [
          {
            key: "Header-Menu",
            path: `${APP_PREFIX_PATH}/menu/header-menu`,
            title: "Header",
            icon: ToTopOutlined,
            breadcrumb: false,
            submenu: [],
            className: "custom-menu-header",
          },

          {
            key: "Footer-Menu",
            path: `${APP_PREFIX_PATH}/menu/footer-menu`,
            title: "Footer",
            icon: SelectOutlined,
            breadcrumb: false,
            submenu: [],
            className: "custom-menu-header",
          },
        ],
      },
    ],
  },
];

const AMAKETEAppNav = [
  {
    key: "AMMAKETE",
    path: `${APP_PREFIX_PATH}/apps`,
    title: "AMKETTE",
    icon: AppstoreOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: "Apps-Project",
        path: `${APP_PREFIX_PATH}/Products`,
        title: "Products",
        icon: CodeSandboxOutlined,
        // image:ProductSvg,
        breadcrumb: true,
        submenu: [
          {
            key: "Apps-Project-Instruments",
            path: `${APP_PREFIX_PATH}/instruments/instruments-list`,
            title: "Instruments",
            icon: DashboardOutlined,
            breadcrumb: true,
          },
          {
            key: "Spare",
            path: `${APP_PREFIX_PATH}/spares`,
            title: "Spares",
            icon: ToolOutlined,
            breadcrumb: true,
            submenu: [
              {
                key: "All-Products-Spare",
                path: `${APP_PREFIX_PATH}/spares/spares-list`,
                title: "All Spare",
                icon: "",
                breadcrumb: false,
                submenu: [],
              },
              {
                key: "Spare-Create",
                path: `${APP_PREFIX_PATH}/spares/add-spares`,
                title: "Add New",
                icon: "",
                breadcrumb: false,
                submenu: [],
              },
            ],
          },
          {
            key: "Accessories",
            path: `${APP_PREFIX_PATH}/accessories`,
            title: "Accessories",
            icon: StarOutlined,
            breadcrumb: true,
            submenu: [
              {
                key: "All-Products-Accessories",
                path: `${APP_PREFIX_PATH}/accessories/accessories-list`,
                title: "All Accessories",
                icon: "",
                breadcrumb: false,
                submenu: [],
              },
              {
                key: "Accessories-Create",
                path: `${APP_PREFIX_PATH}/accessories/add-accessories`,
                title: "Add New",
                icon: "",
                breadcrumb: false,
                submenu: [],
              },
            ],
          },
          {
            key: "Consumable",
            path: `${APP_PREFIX_PATH}/consumables`,
            title: "Consumable",
            icon: ApiOutlined,
            breadcrumb: true,
            submenu: [
              {
                key: "All-Products-Consumable",
                path: `${APP_PREFIX_PATH}/consumables/consumables-list`,
                title: "All Consumables",
                icon: "",
                breadcrumb: false,
                submenu: [],
              },
              {
                key: "Consumable-Create",
                path: `${APP_PREFIX_PATH}/consumables/add-consumables`,
                title: "Add New",
                icon: "",
                breadcrumb: false,
                submenu: [],
              },
            ],
          },
        ],
      },
      {
        key: "Category",
        // path: `${APP_PREFIX_PATH}/apps`,
        path: `${APP_PREFIX_PATH}/category/category`,
        title: "Category",
        icon: DeploymentUnitOutlined,
        // image:CatImage,
        breadcrumb: false,
        submenu: [
          {
            key: "All-category",
            path: `${APP_PREFIX_PATH}/category/category-list`,
            title: "All Category",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "Create-Category",
            path: `${APP_PREFIX_PATH}/category/add-category`,
            title: "Add New",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "Brands",
        path: `${APP_PREFIX_PATH}/apps`,
        title: "Brand",
        icon: SoundOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "All-Brands",
            path: `${APP_PREFIX_PATH}/brands/brand-list`,
            title: "All Brand",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "Create-Brand",
            path: `${APP_PREFIX_PATH}/brands/add-brand`,
            title: "Add New",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "ProductLine",
        path: `${APP_PREFIX_PATH}/apps`,
        title: "Product Line",
        icon: SisternodeOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "All-Product-Line",
            path: `${APP_PREFIX_PATH}/product_line/product_line-list`,
            title: "All Product Line",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "Create-Product_Line",
            path: `${APP_PREFIX_PATH}/product_line/add-product_line`,
            title: "Add New",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "Sections",
        path: `${APP_PREFIX_PATH}/apps`,
        title: "Sections",
        icon: BulbOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "All-Sections",
            path: `${APP_PREFIX_PATH}/sections/section-list?tableRowCount=10`,
            title: "All Sections",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "Create-Edit-Sections",
            path: `${APP_PREFIX_PATH}/sections/add-edit?mode=ADD`,
            title: "Add New",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "Banner",
        path: `${APP_PREFIX_PATH}/apps`,
        title: "Banners",
        icon: ShakeOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "All-Banners",
            path: `${APP_PREFIX_PATH}/banners/banner-list?tableRowCount=10`,
            title: "All Banners",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
          {
            key: "Create-Banners",
            path: `${APP_PREFIX_PATH}/banners/add-edit?mode=ADD`,
            title: "Add New",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
      {
        key: "Quotes",
        path: `${APP_PREFIX_PATH}/apps`,
        title: "Quotes",
        icon: AuditOutlined,
        breadcrumb: true,
        submenu: [
          {
            key: "All-Quotes",
            path: `${APP_PREFIX_PATH}/quotes/quotes-list?tableRowCount=10`,
            title: "All Quotes",
            icon: "",
            breadcrumb: false,
            submenu: [],
          },
        ],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
