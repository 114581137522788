import axios from "axios";
import {
  getBaseURL,
  getRefreshToken,
  getToken,
} from "components/common/others";
import toast from "react-hot-toast";
import { signInWithRefresh } from "services/login";
// import { signInWithRefresh } from "redux/sagas/Auth";

const client = axios.create({
  baseURL: getBaseURL(),
  headers: {
    // 'Content-Type': 'application/json',
    // Accept: 'application/json',
    // "ngrok-skip-browser-warning": "true",
  },
});

const get = (url, body, headers = {}) =>
  client.get(url, {
    params: body,
    headers: headers,
  });

const getBlob = (url, body, headers = {}) =>
  client.get(url, {
    params: body,
    responseType: "arraybuffer",
    headers: headers,
  });

const post = (url, body, headers) => client.post(url, body, { headers });

const put = (url, body, headers) => client.put(url, body, { headers });

const patch = (url, body, headers = {}) => client.patch(url, body, { headers });

const del = (url, body, headers = {}) => client.delete(url, body, { headers });

client.interceptors.request.use(async (config) => {
  config.headers.Authorization = await getToken();
  return config;
});

client.interceptors.response.use(
  function (response) {
    if (response?.data && response?.data && response?.data?.data?.logout) {
      localStorage.removeItem("TOKEN");
      localStorage.setItem("SHOW_TOAST", true);
    }

    return response;
  },
  function (error) {
    if (error?.response?.data?.status === 500) {
      toast.error(error?.response?.statusText);
      return Promise.reject(error);
    } else {
      return Promise.reject(error);
    }
  }
);

client.interceptors.response.use(
  (response) => response,

  async (error) => {
    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      !localStorage.getItem("auth_token")?.length &&
      localStorage.getItem("refresh_token")?.length > 0 &&
      error?.response?.data?.code === "token"
    ) {
      localStorage.removeItem("auth_token");
      localStorage.removeItem("refresh_token");
    }

    if (
      // localStorage.removeItem("refresh_token")?.length &&
      error &&
      error?.response &&
      error.response.status === 401 &&
      error?.response?.data?.code === "token_not_valid"
    ) {
      console.log("here", error?.response?.data?.code);
      const token = await getRefreshToken();

      await signInWithRefresh(token);

      const authenticationToken = await getToken();
      if (authenticationToken) {
        // error.config.headers.Authorization = await getAuthenticationToken();
      }
    }
    return Promise.reject(error);
  }
);

export { get, post, put, del, patch, getBlob };

export default client;
